import { default as ai_45form_45builderOKESZ8JqeBMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editP3w1Tln15mMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexfU0VZPMw73Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexDiK1yNENVMMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexki5mUBfsASMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharelMyQM0Q0fJMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as stats5nIoSnbUptMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionslP3OcTLht8Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showCKQfZ4RcLuMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestYvvVe3v9i1Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexIlQsS3ML7YMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeT7AE4TQXR4Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexsqDZMyqFN9Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginuQQMJJOHDfMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackdDjCF4JqNIMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailrPl8CrzQ4cMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93P4PIdwVQV3Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingdk19OBSVRsMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyVu4Ig9lbRpMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerggSyNncrNiMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensSzZ0ukcjGMMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountHEltp42tplMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminCokceNzMSvMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingDsb101fRm3Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsvirmPrzFkcMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexm14ocNWR27Meta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordGSskyb6hajMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profilechQ4Z2m0hXMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspacetZhqvFDFdbMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsFqO5J1cVgEMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as errorhvuSSn6L5MMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as success23Hdk4IqQTMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93E5qUgx0LiNMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as index1wZbSmWnBUMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93BRywYuzDgvMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesMk7XyBDzAeMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93dBoTDvxG5QMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionssRvXplU3pJMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsIX5bmV5LAjMeta } from "/codebuild/output/src3732301067/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderOKESZ8JqeBMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editP3w1Tln15mMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showCKQfZ4RcLuMeta?.name,
    path: "/forms/:slug()/show",
    meta: showCKQfZ4RcLuMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexDiK1yNENVMMeta?.redirect,
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexki5mUBfsASMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharelMyQM0Q0fJMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: stats5nIoSnbUptMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionslP3OcTLht8Meta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestYvvVe3v9i1Meta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexIlQsS3ML7YMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeT7AE4TQXR4Meta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginuQQMJJOHDfMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailrPl8CrzQ4cMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93P4PIdwVQV3Meta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingdk19OBSVRsMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyVu4Ig9lbRpMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerggSyNncrNiMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsFqO5J1cVgEMeta?.name,
    path: "/settings",
    meta: settingsFqO5J1cVgEMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountHEltp42tplMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminCokceNzMSvMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingDsb101fRm3Meta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsvirmPrzFkcMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexm14ocNWR27Meta?.redirect,
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordGSskyb6hajMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilechQ4Z2m0hXMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspacetZhqvFDFdbMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorhvuSSn6L5MMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: success23Hdk4IqQTMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesMk7XyBDzAeMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionssRvXplU3pJMeta || {},
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src3732301067/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]